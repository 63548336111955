import React, { useState, useEffect } from "react";
import { getReviews } from "../../contentful";
import "./Reviews.scss";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    getReviews().then((data) => setReviews(data));
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      if (index < reviews.length - 1) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
      console.log(index);
    }, 5000);

    return () => {
      clearInterval(id);
    };
  }, [reviews, index]);

  const Review = ({ quote, source, date }) => {
    useEffect(() => {}, [quote]);
    return (
      <div className='ticker'>
        <span className='ticker__quote'>"{quote}"</span>
        <span className='ticker__source'>{source}</span>
        <span className='ticker__source'>{date}</span>
      </div>
    );
  };

  return (
    <div className='review-container'>
      <Review
        quote={reviews[index]?.fields.shortText}
        source={reviews[index]?.fields.place}
        date={reviews[index]?.fields.date}
      />
    </div>
  );
};

export default Reviews;
