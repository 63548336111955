const client = require("contentful").createClient({
  space: "rh9pehpjzrlf",
  accessToken: "C6bW9NDgKCvN1BAybYcqMzk5Iq1bQp_69zquc3BNfZc",
});

const getProjects = () =>
  client
    .getEntries({ content_type: "project", order: "fields.order" })
    .then((response) => response.items);

const getProject = (title) =>
  client
    .getEntries({ content_type: "project", "fields.title": title })
    .then((response) => response.items);

const getHomepage = () =>
  client
    .getEntries({ content_type: "homepage" })
    .then((response) => response.items);

const getReviews = () =>
  client
    .getEntries({ content_type: "review" })
    .then((response) => response.items);

const getBiogPage = () =>
  client
    .getEntries({ content_type: "biogPage" })
    .then((response) => response.items);

const getNextShow = () =>
  client
    .getEntries({ content_type: "nextShow" })
    .then((response) => response.items);

export {
  getProjects,
  getReviews,
  getHomepage,
  getProject,
  getBiogPage,
  getNextShow,
};
