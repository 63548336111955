import React, { useEffect, useState } from "react";
import "./NextShowStyles.scss";
import { getNextShow } from "../../contentful";

const NextShow = () => {
  const [details, setDetails] = useState({});

  useEffect(() => {
    getNextShow().then((data) => setDetails(data?.[0]?.fields));
  }, []);

  if (!details || !details.title) return null;
  return (
    <div className='next-show'>
      <h1>{details.title}</h1>
      <span>{details?.place}</span>
      <span>{details?.date}</span>
      <span>{details?.time}</span>
    </div>
  );
};

export default NextShow;
