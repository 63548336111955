import React, { useEffect, useState } from "react";
import { getReviews } from "../../contentful";
import "./ReviewsStyles.scss";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviews().then((data) => setReviews(data));
  }, []);
  console.log(reviews);
  return (
    <div className='page reviews-page'>
      {reviews.length > 0 &&
        reviews?.map((r) => (
          <div className='reviews-page__review'>
            <h4 className='reviews-page__review__text'>{r?.fields?.text}</h4>
            <p>
              {r.fields.date}, {r.fields.place}
            </p>
          </div>
        ))}
    </div>
  );
};

export default Reviews;
