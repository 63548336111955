import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./ProjectStyles.scss";
import Carousel from "../../components/Carousel/Carousel";
import Youtube from "../../components/Youtube/Youtube";
import { getProjects } from "../../contentful";
import marked from "marked";

const Project = () => {
  const { title } = useParams();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});
  const [divKey, setDivKey] = useState(0);
  const infoRef = useRef();
  const [vidArray, setVidArray] = useState([]);
  useEffect(() => {
    getProjects().then((data) => setProjects(data));
  }, []);

  useEffect(() => {
    setDivKey(Math.random());
    projects.length &&
      setProject(projects.find((p) => p.fields.title === title).fields);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [title, projects]);

  const renderMoreButton = () => {
    const infoSection = infoRef?.current?.getBoundingClientRect().top;
    console.log(infoSection);
    if (project.text || project.pdf || project.youtubeCode)
      return (
        <a className='project__info-btn' href='#info'>
          info +
        </a>
      );
    return null;
  };
  console.log(project.youtubeCode);

  useEffect(() => {
    if (project.youtubeCode) {
      const array = project.youtubeCode.split(",");
      console.log(array);
      setVidArray(array);
    }
  }, [project]);

  return (
    <div key={divKey} className='page'>
      <div className='project__top'>
        <h1 className='project__title'>{project?.title}</h1>
        {project?.author && <h3>{project?.author}</h3>}
        {project?.images && <Carousel items={project?.images} />}
        {renderMoreButton()}
      </div>
      <div ref={infoRef}>
        <div ref={infoRef} className='project__info-container' id='info'>
          {project?.text && (
            <section
              className='project__text'
              dangerouslySetInnerHTML={{ __html: marked(project?.text) }}
            />
          )}
          {project?.pdfImageCarousel && (
            <Carousel pdfViewer items={project.pdfImageCarousel} />
          )}
          <div>
            {project.pdf && (
              <a
                className='project__info-btn'
                href={project.pdf.fields.file.url}
                target='_blank'
              >
                Ireki PDFa
              </a>
            )}
          </div>
          {project?.youtubeCode && (
            <div className='project__youtube-container'>
              {vidArray.map((v) => (
                <div
                  className={`project__youtube ${
                    vidArray.length === 1 && "project__youtube--single"
                  } `}
                >
                  <Youtube code={v} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
