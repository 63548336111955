import React, { useEffect } from "react";
import "./ContactStyles.scss";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className='page contact-page '>
      <h1>Kontaktua</h1>
      <a
        className='contact-page__text'
        href='mailto:maitearreseartabe@gmail.com'
      >
        maitearreseartabe@gmail.com
      </a>
      <p className='contact-page__text'>+34 690 630 748</p>
    </div>
  );
};

export default Contact;
