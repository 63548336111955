import React, { useState, useEffect } from "react";
import "./HomeStyles.scss";
import { getHomepage, getReviews } from "../../contentful";
import marked from "marked";
import Reviews from "../../components/Reviews/Reviews";
import NextShow from "../../components/NextShow/NextShow";

const Home = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    window.scroll(0, 0);
    getHomepage().then((data) => setContent(data[0].fields));
  }, []);
  return (
    <div className='page home-page'>
      {content.image && (
        <>
          <div className='home-page__image'>
            <img src={content?.image.fields.file.url} />
            <p>
              <small className='home-page__image__credit'>
                {content?.imageCredit}
              </small>
            </p>
          </div>
        </>
      )}

      {content.text && (
        <section
          className='home-page__text'
          dangerouslySetInnerHTML={{ __html: marked(content.text) }}
        />
      )}

      <Reviews />
      <NextShow />
    </div>
  );
};

export default Home;
